'use strict';

app.constant('MAPCONSTANT', {
    /**
     * QuartoMap filter panel width variants, in pixels.
     */
    FILTER_WIDTH: Object.freeze({
        NORMAL: 260,
    }),

    /**
     * QuartoMap info panel width variants, in pixels.
     */
    INFO_WIDTH: Object.freeze({
        SMALL: 300,
        NORMAL: 360,
        LARGE: 480,
    }),

    SUBJECT: {
        MAP_BACKLOG_IN_FIELD: "MAP_BACKLOG_IN_FIELD",
        MAP_CROP_HARVESTING_AREA: "MAP_CROP_HARVESTING_AREA",
        MAP_CROP_CHECKERS_COVERAGE: "MAP_CROP_CHECKERS_COVERAGE",
        MAP_CROP_CHECKERS_COVERAGE_LOOSEFRUIT: "MAP_CROP_CHECKERS_COVERAGE_LOOSEFRUIT",
        MAP_BACKLOG: "MAP_BACKLOG",
        MAP_CROP_QUALITY_INSPECTION: "MAP_CROP_QUALITY_INSPECTION",
        MAP_GENERAL_WORK_INSPECTION: "MAP_GENERAL_WORK_INSPECTION",
        MAP_FIELD_INSPECTION: "MAP_FIELD_INSPECTION"
    },

    FIELD_INSPECTION: {
        NATURAL_DAMAGE: "NATURAL_DAMAGE",
        ANIMAL_INVASION: "ANIMAL_INVASION",
        INFRASTRUCTURE_DAMAGE: "INFRASTRUCTURE_DAMAGE",
        DISEASE: "DISEASE",
        OTHERS: "OTHERS"
    },

    HEAT_MAP_GRADIENT: [
        'rgba(0, 0, 0, 0)',
        'rgba(247, 255, 0, 1)',
        'rgba(255, 219, 0, 1)',
        'rgba(255, 153, 0, 1)',
        'rgba(0, 255, 255, 1)',
        'rgba(0, 191, 255, 1)',
        'rgba(0, 127, 255, 1)',
        'rgba(0, 63, 255, 1)',
        'rgba(0, 0, 255, 1)',
        'rgba(0, 0, 223, 1)',
        'rgba(0, 0, 191, 1)',
        'rgba(0, 0, 159, 1)',
        'rgba(0, 0, 127, 1)',
    ]

});
 
